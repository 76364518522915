<template>
  <div style="background: rgb(151, 211, 236); height: 100vh; width: 100vw;overflow-y: scroll;padding-bottom: 22px;">
    <van-loading v-if="isLoading" type="spinner" />
    <div v-else>
      <img style="width: 100%" src="../assets/top-header.png" />
      <div class="title">本周营养饮食</div>
      <div class="time">{{getToday()}}</div>
      <div style="margin-top: 22px;position: relative;" v-for="(item,index) in content" :key="index">
        <img style="width: 100px;position: absolute; z-index: 99; margin-left: -35px; margin-top: -30px;" src="../assets/goods.gif" alt="">
        <van-tag size="large" mark type="success">{{item.showName}}</van-tag>
        <div class="content">
          <div class="img-top"></div>
          <div class="img-content">
            <img style="width: 100%;" :src="getImg(item.masterDishImage)">
          </div>
        </div>
        <div class="content" style="position: relative;">
          <img style="width: 40px;position: absolute; z-index: 99; left: -10px; top: -10px" src="../assets/goods-1.gif" alt="">
          <img style="width: 40px;position: absolute; z-index: 99; right: -10px; top: -10px" src="../assets/goods-1.gif" alt="">
          <div class="img-top"></div>
          <div v-for="(h,j) in item.dayDish" :key="j">
            <van-divider style="padding: 0 44px" dashed><van-tag size="large" type="warning">{{h.showName}}</van-tag></van-divider>
            <div class="des-content" v-for="(v,k) in  h.oneDish" :key="k">
              {{v.name}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <view class="see-more" @click="handleSeeMore">
			查看更多
		</view>
  </div>
</template>

<script>
import { postWeekDishById } from "../api/table";
import { Notify } from "vant";
import dayjs from "dayjs"

export default {
  name: "Informations",
  components: {},
  data() {
    return {
      shiId: "",
      isLoading: true,
      content: null,
      orgId: ''
    };
  },

  methods: {
    handleSeeMore() {
				uni.navigateTo({
					url:'/pages/food/food-list/food-list?orgId=' + this.orgId
				})
    },
    getToday() {
      return dayjs().format('YYYY-MM-DD')
    },
    getImg(img) {
      return process.env.VUE_APP_BASE_API + '/file/' + img;
    },
    async initData() {
      try {
        const { data } = await postWeekDishById({
          id: this.shiId,
        });

        this.content = data.dishList;
        this.isLoading = false;

        console.log("data????", data);
      } catch (error) {
        console.log(error);
        // Notify({ type: 'warning', message: error, duration: 2000 })
      }
    },
  },
  created() {
    this.shiId = this.$route.query?.id ?? "";
    this.orgId = this.$route.query?.orgId ?? "";
    if (!this.shiId) {
      Notify({ type: "warning", message: "缺少参数ID", duration: 2000 });
    } else {
      this.initData();
    }
  },
};
</script>
<style scoped>
.title {
  color: rgb(51, 51, 51);
  font-size: 26px;
  font-weight: bold;
  font-style: normal;
  text-align: left;
  text-decoration: none;
  margin-top: -180px;
  padding: 0px 16px 11px;
}
.time {
  color: rgb(68 67 67);
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  text-align: left;
  padding: 0px 16px 11px;
}

.img-top {
  background-image: url("../assets/line.png");
  background-repeat: repeat;
  width: 100%;
  height: 18px;
  margin: 12px 0 0;
  background-size: contain;
  position: relative;
  background-position: center;
}

.des-content {
  margin: 0 12px;
  padding: 12px;
  text-align: left;
  display: inline-block;
}

.content {
  border: 2px solid #7ca7f8;
  margin: 0 12px;
  position: relative;
  border-top: 0;
}

.van-tag--mark {
    font-size: 20px;
    padding: 12px;
    font-weight: 600;
}

.see-more {
	position: fixed;
	bottom: 22px;
	right: 12px;
	background-color: #063051;
	color: #fff;
	height: 68px;
	width: 68px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	z-index: 999;
}
</style>
