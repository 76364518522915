<template>
  <div>
    <div>
        <van-image fit="contain" style="width: 160px; height: 160px; margin-top: 88px;" :src="require('@/assets/logo_daoshi.jpg')" />  
        <div style="margin-top: 44px;margin-bottom: 44px">
          登录              
        </div>
    </div>
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-field
          v-model="state.telephone"
          name="手机号"
          label="手机号"
          placeholder="手机号"
          type="tel"
          :rules="[{ required: true, message: '请填写手机号' }]"
        />
        <van-field
          v-model="state.code"
          center
          clearable
          label="短信验证码"
          :rules="[{ required: true, message: '请填写验证码' }]"
          placeholder="请输入短信验证码"
        >
          <template #button>
            <van-button v-if="state.time==60" size="small" @click="sendCode" type="primary">发送验证码</van-button>
            <van-button v-else size="small" @click="sendCode" type="primary">{{state.time}}s</van-button>
          </template>
        </van-field>
      </van-cell-group>
      <div style="margin: 44px 16px">
        <van-button round block style="background:#013152" type="primary" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
    <van-loading v-if="state.isSendLoading" size="24px">验证码发送中...</van-loading>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { loginSystem, queryLogInSendCode } from '@/api/user'
import { useRouter } from "vue-router";
import { showNotify } from 'vant';

export default defineComponent({
  components: {},
  setup() {
    const state = reactive({
        telephone: '',
        code: '',
        time: 60,
        isSendLoading: false
    });

    const router = useRouter();

    onMounted(async () => {});

    const sendCode = async () => {
      console.log('sendCode');

				if(!state.telephone) {
          showNotify({ type: 'warning', message: '请填写登录手机号', duration: 2000 })
					return
				}
        state.isSendLoading = true;
				try{
					const result = await queryLogInSendCode({
						telephone: state.telephone
					})
					
					if(result.code == '00') {
            state.isSendLoading = false;
            showNotify({ type: 'success', message: '验证码已发送', duration: 2000 })
						state.time = 60;
						let timer = setInterval(() => {
							if (state.time <= 0) {
								clearInterval(timer);
								timer = null;
								state.time = 60;
								return;
							}
							state.time--;
						}, 1000)
					} else {
						state.isSendLoading = false;
            showNotify({ type: 'warning', message: result.message, duration: 2000 })
					}
					
					
				}catch(e){
					//TODO handle the exception
					console.log(e)
				}
    }

    
    

    const onSubmit = async (values) => {
        console.log('submit', values);
        try {
            const result = await loginSystem({
                logInType: 2,
                code: state.code,
			          telephone: state.telephone,
            })

            if (result.code == '00') {
                
                localStorage.setItem('userInfo', JSON.stringify(result.data));
                showNotify({ type: 'success', message: '登录成功' , duration: 2000 })

                setTimeout(() => {
                  router.back();
                }, 1000);
            } else {
                showNotify({ type: 'warning', message: result.message, duration: 2000 })
            }

        } catch (error) {
            console.log('error', error);
        }
    }

    return {
      state,
      onSubmit,
      sendCode
    };
  },
});
</script>

<style scoped>
</style>
