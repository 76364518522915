<script lang="jsx">
  import { defineComponent } from 'vue';
  import { SORT_ORDER } from '@/config/tableConfig.js';

  export default defineComponent({
    props: {
      sortOrder: {
        type: String,
        default: SORT_ORDER.ALL,
      },
    },
    setup(props) {
      return () => {
        if (props.sortOrder === SORT_ORDER.ASC) {
          return (
            <div className="sort-icon">
              <div className="triangle-asc on"></div>
              <div className="triangle-desc"></div>
            </div>
          );
        } else if (props.sortOrder === SORT_ORDER.DESC) {
          return (
            <div className="sort-icon">
              <div className="triangle-asc"></div>
              <div className="triangle-desc on"></div>
            </div>
          );
        } else if (props.sortOrder === SORT_ORDER.ALL) {
          return (
            <div className="sort-icon">
              <div className="triangle-asc"></div>
              <div className="triangle-desc"></div>
            </div>
          );
        }

        return null;
      };
    },
  });
</script>

<style scoped lang="scss">
  .sort-icon {
    display: inline-block;
    height: 10px;
    margin-left: 4px;
    vertical-align: middle;
  }

  .triangle-asc {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 3px 4px 3px;
    border-color: transparent transparent #86909c transparent;
    &.on {
      border-color: transparent transparent #22b894 transparent;
    }
  }
  .triangle-desc {
    width: 0;
    height: 0;
    margin-top: 2px;
    border-style: solid;
    border-width: 4px 3px 0 3px;
    border-color: #86909c transparent transparent transparent;
    &.on {
      border-color: #22b894 transparent transparent transparent;
    }
  }
</style>
