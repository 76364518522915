<template>
  <div class="page-container">
    <div
      class="content-item"
      v-for="(item, index) in state.list"
      :key="index"
    >
      <div class="item-header-info">
        <div class="item-header-info-name">{{item.businessName}} <span>￥{{item.signMoney}}</span></div>
        <div class="item-header-info-status-text">{{SigningStatusText[item.signStatus]}}</div>
      </div>
      <div class="item-header-info">
        <div class="item-des">{{ getDay(item.checkInDate) }}~{{ getDay(item.expireDate) }}</div>
        <div class="item-header-info-status-value">剩余{{item.record ?? '--'}}天</div>
      </div>
      <div class="item-header-info">
        <div class="item-des">{{item.bedNo || '--'}}</div>
        <!-- <div class="item-header-info-status-value">{{item.}}</div> -->
      </div>
      <div style="border-top: 1px solid #e9f1f8; margin-top: 10px;box-sizing: content-box;padding-top: 10px;" class="item-header-info" @click="handleGoToSignin(item)">
        <div class="item-header-info-name">已签到 <span>{{item.record || '--'}}天</span></div>
        <div class="item-header-info-status-value">
          <van-icon name="arrow" />
        </div>
      </div>
      <!-- <div class="item-header-info">
        <div class="item-des">最新时间：2024-09-01~2024-12-31</div>
      </div> -->
    </div>

    <!-- <div class="content-item">
      
    </div> -->
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { queryBusinessUserSelectPage } from '@/api/user'
import { SigningStatusText } from '@/utils/const'
import dayjs from "dayjs";

export default defineComponent({
  name: "StudentsInfo",

  components: {},

  setup() {
    const route = useRoute();

    const router = useRouter();

    const state = reactive({
        list: []
    })

    onMounted(() => {
        queryBusinessUserSelectPageData()
    });


    const queryBusinessUserSelectPageData = async () => {
      try {
        const result = await queryBusinessUserSelectPage({
            page:{
                pageIndex: 1,
                pageSize: 100
            },
            userId: route?.query?.userId
        });
        console.log("result:", result);
        if (result.code === "00") {
          state.list = result.data?.list ?? []
        } else {
          state.list = [];
        }
      } catch (error) {
        console.log("error:>>>", error);
        state.list = [];
      }
    }

    const handleGoToSignin = (row) => {
      router.push({
        path: "/signIn",
        query: {
          userId: route?.query?.userId,
          businessUserId: row?.id,
          userName: row?.userName,
          checkInDate: row?.checkInDate,
          expireDate: row?.expireDate,
          performanceId: row?.performanceId
        },
      });
    };

    const getDay = (day) => {
        return dayjs(day).format('YYYY-MM-DD')
    }

    return {
      handleGoToSignin,
      state,
      SigningStatusText,
      getDay
    };
  },
});
</script>

<style lang="scss" scoped>
.page-container {
  background: #e9f1f8;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;

  .content-item {
    background: #fff;
    margin: 12px 8px;
    border-radius: 4px;
    padding: 12px;
    text-align: left;
  }

  .item-header {
    display: flex;
  }

  .item-header-info {
    display: flex;
    justify-content: space-between;
    padding: 4px 0;
    align-items: center;

    .item-des {
      color: #333333;
      font-size: 14px;
      vertical-align: middle;
    }

    .item-header-info-name {
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 18px;
      color: #000000;
      font-weight: 600;

      span {
        font-size: 16px;
        font-weight: 500;
        color: burlywood;
      }
    }

    .item-header-info-status {
      color: #fff;
      font-size: 14px;
      background: #113050;
      padding: 4px 12px;
      border-radius: 24px;
    }

    &-text {
      color: #113050;
      font-size: 12px;
      padding: 4px 12px;
    }

    &-value {
      font-size: 12px;
      padding: 4px 12px;
    }

    .item-header-info-other {
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      display: flex;
      align-items: center;

      p {
        color: #ff3638;
      }
    }
  }
}
</style>