import axios from 'axios';
import FormData from 'form-data';

export const uploadFile = (file, params) => {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token') || '');
    formData.append('file',  file.file);
    return axios.post('http://testw.ubetterplus.com/app/file/upload?bsnType=' + params.bsnType, formData, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    });
  }