<template>
  <div class="page-container">
    <div class="page-header">
         <van-tabs v-model:active="active" type="card" @click-tab="handleClickTab">
            <van-tab :name="1" title="获得"></van-tab>
            <van-tab :name="0" title="支出"></van-tab>
        </van-tabs>
    </div>
    <div class="content-item" v-for="(item, index) in state.list" :key="index">
      <div class="item-header-info">
        <div class="item-header-info-name">
          <div>{{item.name || '--'}}</div>
          <div class="item-header-info-other">
            {{item.time || '--'}}
          </div>
        </div>
        <div class="item-header-info-status-value">
          {{item.num || '--'}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent, onMounted, reactive, ref } from 'vue';
  import { useRoute, useRouter } from "vue-router";
  import { ModuleType, SexText, HospitalStatusText } from '@/utils/const' 
  import { queryGetSignStu } from "@/api/user";
  import EmptyData from '@/components/EmptyData.vue';
  import DefaultAvatar from '@/assets/avatar.jpg';
  import { getImg } from '@/utils/methods';
  import dayjs from "dayjs";

  export default defineComponent({
    name: 'PointsDetail',
    
    components: {
        EmptyData
    },

    setup() {
     const route = useRoute();

     const router = useRouter();

     const active = ref(1)

     const state = reactive({
        list: [
          {
            name: '休息晚归',
            time: '2021-10-22 16:04:32',
            num: '-2'
          },
          {
            name: '兑换：10U米',
            time: '2024-10-03 12:00:00',
            num: '-100'
          },
          {
            name: '兑换：U宝书签',
            time: '2024-12-12 12:44:00',
            num: '-500'
          },
          {
            name: '奖励所得',
            time: '2024-11-02 13:20:57',
            num: '+600'
          }
        ],
     })

      onMounted(() => {
        // queryGetSignStuData();
       
       
      });

    const queryGetSignStuData = async () => {
      try {
        const params = {
            signStatus: active.value
        }
       
        const result = await queryGetSignStu({
            page:{
                pageIndex: 1,
                pageSize: 10
            },
            ...params
        });
        console.log("result:", result);
        if (result.code === "00") {
          state.list = result.data?.list ?? []
        } else {
          state.list = [];
        }
      } catch (error) {
        console.log("error:>>>", error);
        state.list = [];
      }
    }

      const handleClickTab = () => {
        // queryGetSignStuData();
      };

      const getDay = (day) => {
        return dayjs(day).format('YYYY-MM-DD')
      }

      return {
        active,
        state,
        ModuleType,
        DefaultAvatar,
        getImg,
        handleClickTab,
        SexText,
        HospitalStatusText,
        getDay
      };
    },
  });
</script>

<style lang="scss" scoped>
.page-container {
  background: #e9f1f8;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
}

.page-header {
    padding: 12px;
    margin: 0 68px;
}

.content-item {
  background: #fff;
  margin: 12px 8px;
  border-radius: 4px;
  padding: 12px;
  text-align: left;
}

.item-header-info {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
  align-items: center;

  .item-des {
    color: #333333;
    font-size: 14px;
    vertical-align: middle;
  }

  .item-header-info-name {
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    font-weight: 600;

    span {
      font-size: 16px;
      font-weight: 500;
      color: burlywood;
    }
  }

  .item-header-info-status {
    color: #fff;
    font-size: 14px;
    background: #113050;
    padding: 4px 12px;
    border-radius: 24px;
  }

  &-text {
    color: #113050;
    font-size: 12px;
    padding: 4px 12px;
  }

  &-value {
    font-size: 12px;
    padding: 4px 12px;
  }

  .item-header-info-other {
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    display: flex;
    align-items: center;

    p {
      color: #ff3638;
    }
  }

  
}
</style>