import request from '../utils/request'

export function postDayDishDris(params,token) {
  return request({
    url: '/syDayDish/getDris',
    method: 'post',
    data:params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'token': token
    }
  })
}

  export function postSyparamSelectList(params) {
    return request({
      url: '/syparam/selectList',
      method: 'post',
      data:params
    })
  }




