export const tableConfig = {
  truckNoColWidth: '95px',
};

export const SORT_ORDER = {
  ALL: 'all',
  DESC: 'desc',
  ASC: 'asc',
};

