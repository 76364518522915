<template>
  <div class="page-activity">
    <van-image
      width="100vw"
      height="auto"
      fit="cover"
      :src="ActivityImage"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import ActivityImage from '@/assets/chuang.png'

export default defineComponent({
  name: "ActivityGraphic",

  components: {},

  setup() {


    onMounted(() => {
    });


    return {
      ActivityImage
    };
  },
});
</script>

<style lang="scss" scoped>
.page-activity {
  background: #e9f1f8;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
  padding: 0;
  margin: 0;
}
</style>