<template>
  <div class="page-container">
    <div class="public-card">
      <van-cell title="姓名" is-link>
        <template #value>
          <van-field v-model="state.userInfo.realName" placeholder="请输入姓名" :border="false" />
        </template>
      </van-cell>
      <van-cell @click="state.sexShow = true" title="性别" :value="SexText[state.userInfo.sex]" size="large" is-link arrow-direction="down" />
      <van-cell @click="state.gradeShow = true" title="年级" :value="state.GradeText[state.userInfo.grade]" size="large" is-link arrow-direction="down" />
      <van-cell @click="state.birthdayShow = true" title="生日" :value="state.userInfo.birthday ?? '--'" size="large" is-link arrow-direction="down" />

      <van-cell title="兴趣爱好" is-link>
        <template #value>
          <van-field maxlength="50" v-model="state.userInfo.hobby" placeholder="请输入兴趣爱好" :border="false" />
        </template>
      </van-cell>
      <van-cell title="学习情况" is-link>
        <template #value>
          <van-field maxlength="50" v-model="state.userInfo.learning" placeholder="请输入学习情况" :border="false" />
        </template>
      </van-cell>
      <van-cell title="过敏情况" is-link>
        <template #value>
          <van-field maxlength="50" v-model="state.userInfo.allergy" placeholder="请输入过敏情况" :border="false" />
        </template>
      </van-cell>
      <van-cell title="口味偏好" is-link>
        <template #value>
          <van-field maxlength="50" v-model="state.userInfo.taste" placeholder="请输入口味偏好" :border="false" />
        </template>
      </van-cell>
      <van-cell title="作息时间" is-link>
        <template #value>
          <van-field maxlength="50" v-model="state.userInfo.workRest" placeholder="请输入作息时间" :border="false" />
        </template>
      </van-cell>
      <van-cell title="家庭情况" is-link>
        <template #value>
          <van-field maxlength="50" v-model="state.userInfo.familySituation" placeholder="请输入家庭情况" :border="false" />
        </template>
      </van-cell>
      <van-cell title="其他备注" is-link>
        <template #value>
          <van-field maxlength="50" v-model="state.userInfo.notes" placeholder="请输入备注" :border="false" />
        </template>
      </van-cell>
    </div>

    <div class="page-footer">
      <button class="save-btn" @click="handleSumit">保存</button>
    </div>


    <van-action-sheet v-model:show="state.sexShow" :actions="[{name: '男', value: 1},{name: '女', value: 2}]" @close="onClose" @select="handleChoiceSex" />
    <van-action-sheet v-model:show="state.gradeShow" :actions="state.gradeList" @close="onClose" @select="onGradeSelect" />


    <van-popup v-model:show="state.birthdayShow" round position="bottom">
      <van-date-picker
        type="date"
        v-model="currentDate"
        @confirm="onSelectBirthday"
        @cancel="onClose"
        :min-date="state.minDate"
      />
    </van-popup>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { queryGetSignStuDetail, querySyUserUpdate } from "@/api/user";
import { SexText, HospitalStatusText } from "@/utils/const";
import dayjs from "dayjs";
import { showNotify } from "vant";
import { postSyparamSelectList } from '@/api/report'

export default defineComponent({
  name: "EditStudentsInfo",

  components: {
    
  },

  setup() {
    const router = useRouter();
    const route = useRoute();

    const currentDate = ref()

    const state = reactive({
      sexShow:false,
      birthdayShow: false,
      gradeShow: false,
      minDate: new Date(2000, 0, 1),
      maxDate: new Date(2018, 11, 31),
      formatter(type, value) {
        if (type === 'year') {
          return `${value}年`;
        }
        if (type === 'month') {
          return `${value}月`;
        }

        if (type === 'day') {
          return `${value}日`;
        }
        return value;
      },
      GradeText: {},
      userId: route?.query?.userId,
      gradeList: [],
      userInfo: {
        realName: null,
        allergy: null,
        birthday: null,
        familySituation: null,
        grade: null,
        hobby: null,
        id: null,
        learning: null,
        notes: null,
        sex: null,
        taste: null,
        workRest: null
      },
    });

    onMounted(async () => {
      await getSyparamSelectList()
      queryGetSignStuDetailData();
    });

    const handleChoiceSex= (opt) => {
      state.userInfo.sex = opt.value
      state.sexShow = false
      console.log('opt', opt)
    }

    const onSelectBirthday = (opt) => {
      state.birthdayShow = false
      console.log('opt', opt)
      state.userInfo.birthday = opt.selectedValues.join('-')
    }

    const onClose = () => {
      state.birthdayShow = false
      state.sexShow = false
      state.gradeShow = false
    }

    const getSyparamSelectList = async() => {
      try {
        const result = await postSyparamSelectList({
          bigCode: 'userGrade',
        })

        if(result.code === '00') {
          state.GradeText = {}
          state.gradeList = result.data ?? []
          result.data.forEach(item => {
            state.GradeText[item.code] = item.descripition
            state.gradeList.push({
              name: item.descripition,
              value: item.code
            })
          })
        }

        console.log('userGrade', result)
      } catch (error) {
        
      }
    }

    const handleSumit = async () => {
      try {
        const result = await querySyUserUpdate({
          ...state.userInfo,
          userId: route?.query?.userId
        })
        if(result.code === '00') {
          showNotify({ type: 'success', message: '个人信息保存成功', duration: 2000 })

          setTimeout(() => {
            router.back();
          }, 1000);
        } else {
          showNotify({ type: 'warning', message: result.message, duration: 2000 })
        }
      } catch (error) {
        console.log(error)
      }
    }

    const queryGetSignStuDetailData = async () => {
      try {
        const result = await queryGetSignStuDetail({
          id: route?.query?.userId,
        });
        console.log("result>>>>", result);
        for (const key in state.userInfo) {
          if (Object.prototype.hasOwnProperty.call(state.userInfo, key)) {
            const element = result.data[key];
            state.userInfo[key] = element
          }
        }

        currentDate.value = [dayjs(state.userInfo.birthday).format('YYYY'),dayjs(state.userInfo.birthday).format('MM'),dayjs(state.userInfo.birthday).format('DD')]
        console.log(currentDate)
      } catch (error) {
        console.log("error>>>>", error);
      }
    };

    const getDay = (day) => {
      return dayjs(day).format("YYYY-MM-DD");
    };

    const onGradeSelect = (opt) => {
      state.userInfo.grade = opt.value
      state.gradeShow = false
    }

    return {
      state,
      HospitalStatusText,
      SexText,
      getDay,
      handleChoiceSex,
      onSelectBirthday,
      onClose,
      onGradeSelect,
      currentDate,
      handleSumit
    };
  },
});
</script>

<style lang="scss" scoped>
.page-container {
  background: #e9f1f8;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;

  .public-card{
    background: #FFFFFF;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: space-between; 
    justify-content: flex-start;
    margin-top: 8px;
  }

  .page-footer {
    // position: absolute;
    // bottom: 88px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .save-btn {
    background-color: #013152;
    height: 40px;
    width: 206px;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    margin-bottom: 12px;
  }

  :deep(.van-field__control) {
    text-align: right !important;
  }

  :deep(.van-cell__value) {
    font-weight: 500 !important;
    font-size: 35rpx !important;
    color: #505050 !important;
    width: 80% !important;
  }

  :deep(.van-field__value) {
    margin-right: -12px !important;
  }

  :deep(.van-cell__title) {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    padding-left: 8px;
  }

  :deep(.van-cell) {
    display: flex !important;
    align-items: center !important;
  }

}
</style>