<template>
  <div class="page-container">
    <div class="content-item">
      <div class="item-header-info">
        <div class="item-header-info-name">学生</div>
        <div class="item-header-info-status-value">{{state.signInInfo.sudentInfo.userName}}</div>
      </div>

      <div class="item-header-info">
        <div class="item-header-info-name">签约周期</div>
        <div class="item-header-info-status-value">{{getDay(state.signInInfo.sudentInfo.checkInDate)}} ~ {{getDay(state.signInInfo.sudentInfo.expireDate)}}</div>
      </div>

      <div class="item-header-info">
        <div class="item-header-info-name">已签到</div>
        <div class="item-header-info-status-value">{{state.signInInfo.sudentInfo.record}}天</div>
      </div>
    </div>

    <div class="date-picker">
      <van-calendar
        :show-title="false"
        :show-confirm="false"
        :first-day-of-week="1"
        :min-date="state.minDate"
        :max-date="state.maxDate"
        :value="value"
        @select="onSelect"
        :poppable="false"
        switch-mode="month"
        :default-date="null"
        :formatter="formatter"
      />
    </div>

    <div v-if="state.signInInfo.currentSignInfo" class="content-item" style="margin-bottom: 128px">
      <div class="item-header-info">
        <div class="item-header-info-status-value">签到状态</div>
        <div class="item-header-info-status-value">{{state.signInInfo.currentSignInfo.status == 1 ? '已签到' : '未签到'}}</div>
      </div>

      <div class="item-header-info">
        <div class="item-header-info-status-value">签约签到时间</div>
        <div class="item-header-info-status-value">{{getDay(state.signInInfo.currentSignInfo.performanceTime)}}</div>
      </div>

      <div class="item-header-info">
        <div class="item-header-info-status-value">是否人工修正</div>
        <div class="item-header-info-status-value">{{state.signInInfo.currentSignInfo.isUpdate ? "是" : "否"}}</div>
      </div>

      <div class="item-header-info">
        <div class="item-header-info-status-value">修正时间</div>
        <div class="item-header-info-status-value">{{state.signInInfo.currentSignInfo.updateTime ?? '--'}}</div>
      </div>
      <div class="item-header-info">
        <div class="item-header-info-status-value">修正人</div>
        <div class="item-header-info-status-value">{{state.signInInfo.currentSignInfo.updateNameStr ?? '--'}}</div>
      </div>
      <div class="item-header-info">
        <div class="item-header-info-status-value">修正原因</div>
        <div class="item-header-info-status-value">
          {{state.signInInfo.currentSignInfo.updateReason ?? '--'}}
        </div>
      </div>
    </div>

    <div class="item-footer">
      <van-button
        size="normal"
        style="width: 140px;font-size: 16px"
        plain
        hairline
        type="primary"
        @click="handleGoTravelRecords"
        >查看出行情况</van-button
      >
      <van-button
        size="normal"
        style="width: 140px;font-size: 16px"
        plain
        type="success"
        @click="handleChangeRecords"
        >修改</van-button
      >
    </div>

    <van-popup
      position="bottom"
      round
      v-model:show="isPopUpShow"
      :style="{ padding: '12px 0px' }"
    >
      <van-form @submit="onSubmit">
        <van-cell-group inset style="margin-top: 12px">
          <van-field
            v-model="state.signInInfo.form.statusText"
            name="签到状态"
            label="签到状态"
            placeholder="请选择签到状态"
            is-link
            @click="showPicker = true"
            :rules="[{ required: true, message: '请选择签到状态' }]"
          />
          <van-field
            v-model="state.signInInfo.form.updateReason"
            type="textarea"
            name="修正原因"
            label="修正原因"
            rows="2"
            autosize
            placeholder="修正原因"
            maxlength="50"
            show-word-limit
            :rules="[{ required: true, message: '请填写修正原因' }]"
          />
        </van-cell-group>
        <div style="margin: 16px">
          <van-button round block type="primary" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
    </van-popup>

    <van-popup v-model:show="showPicker" round position="bottom">
      <van-picker
        :columns="columns"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { querySyPerformanceDetail, querySyPerformanceUpdate, querySyPerformanceAdd } from '@/api/user'
import { showNotify } from 'vant';
export default defineComponent({
  name: "SignIn",

  components: {},

  setup() {
    const route = useRoute();

    const router = useRouter();

    const dateToday = dayjs();
    const dateExpire = dayjs(route.query.expireDate);
    const diff = dateToday.diff(dateExpire);
    let _maxDate = null
    if (diff < 0) {
        _maxDate = dateToday
    } else {
        _maxDate = dateExpire
    }


    const state = reactive({
      minDate: new Date(dayjs(route.query.checkInDate).format("YYYY-MM-DD")),
      maxDate: new Date(_maxDate),
      
      signInInfo: {
        rangeList: [],
        currentSignInfo: null,
        currentSelectDate: null,
        sudentInfo: {
            userId: route?.query?.userId,
            userName: route?.query?.userName,
            checkInDate: dayjs(route.query.checkInDate).format("YYYY-MM-DD"),
            expireDate: dayjs(route.query.expireDate).format("YYYY-MM-DD"),
            record: 0,
            performanceId: route?.query?.performanceId
        },
        form: {
            status: null,
            updateReason: null,
            statusText: null
        },
      }
    });

    const columns = [
      { text: "已签", value: 1 },
      { text: "未签", value: 0 },
    ];

    const isPopUpShow = ref(false);

    const showPicker = ref(false);

    const handleGoTravelRecords = () => {
      router.push({
        path: "/travelRecords",
        query: {
          userId: route?.query?.userId,
        },
      });
    };

    const handleChangeRecords = () => {
        if(!state.signInInfo.currentSelectDate) {
            showNotify({ type: 'warning', message: '点击选择日期', duration: 2000 })
            return
        }
        isPopUpShow.value = true
    }

    const onConfirm = ({ selectedOptions }) => {
      showPicker.value = false;
      console.log(selectedOptions);
      state.signInInfo.form.statusText = selectedOptions[0].text;
      state.signInInfo.form.status = selectedOptions[0].value;
    };

    const onSubmit = async () => {
      
      console.log(state.signInInfo.form);


      try {
            let result = null
            if (
              state.signInInfo.currentSignInfo &&
              state.signInInfo.currentSignInfo.id
            ) {
              result = await querySyPerformanceUpdate({
                performanceId: state.signInInfo.sudentInfo.performanceId,
                id: state.signInInfo.currentSignInfo.id,
                status: state.signInInfo.form.status,
                updateReason: state.signInInfo.form.updateReason,
                isUpdate: true,
                performanceTime: dayjs(state.signInInfo.currentSelectDate).format(
                  "YYYY-MM-DD HH:mm:ss"
                ),
                userId: state.signInInfo.currentSignInfo.userId,
                userName: state.signInInfo.currentSignInfo.userName,
                startTime: state.signInInfo.currentSignInfo.startTime,
                endTime: state.signInInfo.currentSignInfo.endTime,

              });
              
            } else {
              result = await querySyPerformanceAdd({
                performanceId: route.query.performanceId,
                status: state.signInInfo.form.status,
                updateReason: state.signInInfo.form.updateReason,
                isUpdate: true,
                performanceTime: dayjs(state.signInInfo.currentSelectDate).format(
                  "YYYY-MM-DD HH:mm:ss"
                ),
                userId: state.signInInfo.sudentInfo.userId,
                userName: state.signInInfo.sudentInfo.userName,
                startTime: state.signInInfo.sudentInfo.checkInDate,
                endTime: state.signInInfo.sudentInfo.expireDate,
              });
            }

            if (result.code == "00") {
                isPopUpShow.value = false;
                showNotify({ type: 'success', message: '签到修改成功', duration: 2000 })
                querySyPerformanceDetailData();
              } else {
                 showNotify({ type: 'warning', message: result.message, duration: 2000 })
              }
          } catch (error) {
            console.log(error)
          }
    };

    onMounted(() => {
        querySyPerformanceDetailData()
    });

    const querySyPerformanceDetailData = async () => { 
        try {
            const result = await querySyPerformanceDetail({ 
                userId: route?.query?.userId,
                businessUserId: route?.query?.businessUserId
            })
            if(result.code === '00') {
                state.signInInfo.rangeList = result?.data ?? []
                state.signInInfo.sudentInfo.record = result?.data?.[0]?.record ?? 0

                if (state.signInInfo.currentSignInfo) {
                    state.signInInfo.currentSignInfo = state.signInInfo.rangeList.filter(
                        (item) =>
                            dayjs(item.performanceTime).format("YYYY-MM-DD") ===
                            dayjs(state.signInInfo.currentSignInfo.performanceTime).format("YYYY-MM-DD")
                    )[0];
                    state.signInInfo.form.status = state.signInInfo.currentSignInfo.status;
                    state.signInInfo.form.statusText = state.signInInfo.currentSignInfo.status == 1 ? '已签' : '未签';
                    state.signInInfo.form.updateReason =
                    state.signInInfo.currentSignInfo.updateReason;
                } else {
                    state.signInInfo.form.status = null;
                    state.signInInfo.form.updateReason = null;
                }
            } else {
                state.signInInfo.rangeList = [];
                state.signInInfo.sudentInfo.record = 0
            }
        } catch (error) {
            console.log(error)
            state.signInInfo.rangeList = [];
            state.signInInfo.sudentInfo.record = 0
        }
        
    }

    const onSelect = (options) => {
        state.signInInfo.currentSelectDate = dayjs(options).format("YYYY-MM-DD");
        console.log(options)
        state.signInInfo.currentSignInfo = state.signInInfo.rangeList.filter(
        (item) =>
          dayjs(item.performanceTime).format("YYYY-MM-DD") ===
          dayjs(options).format("YYYY-MM-DD")
      )[0];
      if (state.signInInfo.currentSignInfo) {
        state.signInInfo.form.status = state.signInInfo.currentSignInfo.status;
        state.signInInfo.form.statusText = state.signInInfo.currentSignInfo.status == 1 ? '已签' : '未签';
        state.signInInfo.form.updateReason =
        state.signInInfo.currentSignInfo.updateReason;
      } else {
        state.signInInfo.form.status = null;
        state.signInInfo.form.updateReason = null;
      }
    }

    const formatter = (day) => {

      const rangeList = state.signInInfo.rangeList.map((item) =>
        dayjs(item?.performanceTime).format("YYYY-MM-DD")
      );
      if (!rangeList.length) {
        day.bottomInfo = ''
        day.className = ''
      };


      if (rangeList.includes(dayjs(day.date).format("YYYY-MM-DD"))) {
        const find = state.signInInfo.rangeList.find(
          (item) =>
            dayjs(item.performanceTime).format("YYYY-MM-DD") ===
            dayjs(day.date).format("YYYY-MM-DD")
        );
        if (find.status == 1) {
            day.bottomInfo = "已签";
            day.className = 'van-calendar-day-true'
        } else {
            day.bottomInfo = "未签";
            day.className = 'van-calendar-day-false'
        }
      } else {
        day.bottomInfo = ''
        day.className = ''
      }
      return day;
    }

    const getDay = (day) => {
        return dayjs(day).format('YYYY-MM-DD')
    }

    return {
      state,
      handleGoTravelRecords,
      isPopUpShow,
      showPicker,
      columns,
      onConfirm,
      onSubmit,
      formatter,
      onSelect,
      handleChangeRecords,
      getDay
    };
  },
});
</script>

<style lang="scss" scoped>
.page-container {
  background: #e9f1f8;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
}

.content-item {
  background: #fff;
  margin: 12px 8px;
  border-radius: 4px;
  padding: 12px;
  text-align: left;
}

.item-header-info {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
  align-items: center;

  .item-des {
    color: #333333;
    font-size: 14px;
    vertical-align: middle;
  }

  .item-header-info-name {
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    font-weight: 600;

    span {
      font-size: 16px;
      font-weight: 500;
      color: burlywood;
    }
  }

  .item-header-info-status {
    color: #fff;
    font-size: 14px;
    background: #113050;
    padding: 4px 12px;
    border-radius: 24px;
  }

  &-text {
    color: #113050;
    font-size: 12px;
    padding: 4px 12px;
  }

  &-value {
    font-size: 12px;
    padding: 4px 12px;
  }

  .item-header-info-other {
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    display: flex;
    align-items: center;

    p {
      color: #ff3638;
    }
  }
}

.item-footer {
  position: fixed;
  bottom: 0px;
  display: flex;
  justify-content: space-around;
  width: 100%;
  background: #fff;
  padding: 22px 0;
  border-top: 10px solid #e9f1f8;
}

:deep(.van-calendar-day-true) {
    .van-calendar__bottom-info {
        color: #52c41a;
    }
    .van-calendar__bottom-info::before {
        content: '';
        display: inline-block;
        width: 6px; /* 小圆点直径 */
        height: 6px; /* 小圆点直径 */
        background-color: #52c41a; /* 小圆点颜色 */
        border-radius: 50%; /* 圆形 */
        margin-right: 5px; /* 与文本的间隔 */
    }
}

:deep(.van-calendar-day-false) {
    .van-calendar__bottom-info {
        color: #faad14;
    }
    .van-calendar__bottom-info::before {
        content: '';
        display: inline-block;
        width: 6px; /* 小圆点直径 */
        height: 6px; /* 小圆点直径 */
        background-color: #faad14; /* 小圆点颜色 */
        border-radius: 50%; /* 圆形 */
        margin-right: 5px; /* 与文本的间隔 */
    }
}
</style>