<template>
  <div class="page-container">
    <div class="content-item">
      <div class="item-header">
        <van-image
          width="80"
          height="80"
          :src="avatarImg"
          fit="contain"
        >
          <template v-slot:loading>
            <van-loading type="spinner" size="20" />
          </template>
          <template v-slot:error>
            <van-image width="80" height="80" :src="DefaultAvatar" />
          </template>
        </van-image>
        <div style="margin-left: 12px; flex: 1">
          <div class="item-header-info">
            <div class="item-header-info-name">
              {{ state.studentInfo?.realName || "--" }}
              <span>{{ SexText[state.studentInfo?.sex] }}</span>
            </div>
            <div class="item-header-info-status">
              {{ HospitalStatusText[state.studentInfo?.status] }}
            </div>
          </div>
          <div class="item-header-info" >
            <div>{{state.studentInfo?.gradeDesc ?? '--'}} &nbsp;&nbsp;&nbsp;&nbsp; <span> {{state.studentInfo?.birthday ?? '--'}}</span></div>
          </div>
        </div>
      </div>

      <div style="display: flex; flex-direction: column; justify-content: flex-start;margin-top: 12px;">
        <div class="header-title">兴趣爱好：<span>{{state.studentInfo?.hobby ?? '--'}}</span></div>
        <div class="header-title">学习情况：<span>{{state.studentInfo?.learning ?? '--'}}</span></div>
        <div class="header-title">过敏情况：<span>{{state.studentInfo?.allergy ?? '--'}}</span></div>
        <div class="header-title">口味偏好：<span>{{state.studentInfo?.taste ?? '--'}}</span></div>
        <div class="header-title">作息时间：<span>{{state.studentInfo?.workRest ?? '--'}}</span></div>
        <div class="header-title">家庭情况：<span>{{state.studentInfo?.familySituation ?? '--'}}</span></div>
        <div class="header-title">其他备注：<span>{{state.studentInfo?.notes ?? '--'}}</span></div>
      </div>

      <div style="margin-top: 12px;display: flex;align-items: center;justify-content: flex-start;">
          <van-uploader :after-read="afterRead" accept="image/*">
              <van-button size="small" plain type="primary"
                >更换人脸照片</van-button
              >
            </van-uploader>

            <van-button style="margin-left: 12px" size="small" plain type="primary"
               @click="handleGoEditStudentInfo"
                >编辑信息</van-button
              >
        </div>
    </div>
    <div
      class="content-item"
      v-if="state.studentInfo && state.studentInfo.signStatus === 1"
    >
      <div class="item-header-info">
        <div class="item-header-info-name">
          {{ state.studentInfo.businessName }}
          <span>￥{{ state.studentInfo.signStatus }}</span>
        </div>
        <div class="item-header-info-status-text">签约中</div>
      </div>
      <div class="item-header-info">
        <div class="item-des">
          {{ getDay(state.studentInfo.checkInDate) }}~{{
            getDay(state.studentInfo.expireDate)
          }}
        </div>
        <div class="item-header-info-status-value">
          剩余{{ state.studentInfo.leftOver || "--" }}天
        </div>
      </div>
      <div class="item-header-info">
        <div class="item-des">{{ state.studentInfo.bedNo || "--" }}</div>
      </div>

      <div
        class="item-header-info"
        style="
          margin-top: 10px;
          border-top: 1px solid #e9f1f8;
          box-sizing: content-box;
          padding-top: 10px;
        "
        @click="handleGoToSignin"
      >
        <div class="item-header-info-name">
          已签到 <span>{{ state.studentInfo.record || "--" }}天</span>
        </div>
        <div class="item-header-info-status-value">
          <van-icon name="arrow" />
        </div>
      </div>
      <!-- <div class="item-header-info">
        <div class="item-des">最新时间：2024-09-01~2024-12-31</div>
      </div> -->
    </div>

    <div class="content-item">
      <div class="item-header-info" @click="handleGoTravelRecords">
        <div class="item-header-info-name">出行情况</div>
        <div class="item-header-info-status-value">
          <van-icon name="arrow" />
        </div>
      </div>
    </div>

    <div class="content-item">
      <div class="item-header-info" @click="handleGoMealRecords">
        <div class="item-header-info-name">餐食记录</div>
        <div class="item-header-info-status-value">
          <van-icon name="arrow" />
        </div>
      </div>
    </div>

    <div class="content-item">
      <div class="item-header-info" @click="handleGoiSignInRecords">
        <div class="item-header-info-name">签约记录</div>
        <div class="item-header-info-status-value">
          <van-icon name="arrow" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { queryGetSignStuDetail, querySyUserUpdateFace } from "@/api/user";
import DefaultAvatar from "@/assets/avatar.jpg";
import { getImg } from "@/utils/methods";
import { SexText, HospitalStatusText } from "@/utils/const";
import dayjs from "dayjs";
import { uploadFile } from "@/api/uploadFile";
import { showNotify } from "vant";

export default defineComponent({
  name: "StudentsInfo",

  components: {
    
  },

  setup() {
    const router = useRouter();
    const route = useRoute();

    const state = reactive({
      userId: route?.query?.userId,
      studentInfo: null,
    });

    onMounted(() => {
      queryGetSignStuDetailData();
    });

    const avatarImg = computed(() => {
      return getImg(state.studentInfo?.faceImg) || DefaultAvatar;
    });

    const afterRead = async (file) => {
      console.log(file);
      try {
        const { data: result } = await uploadFile(file, {
          bsnType: 7,
        });
        console.log(result);
        if(result.code == '00') {
          querySyUserUpdateFace({
            id: route?.query?.userId,
            faceImg: result.data?.[0].fileUrl,
          }).then((response) => {
            if (response.code == "00") {
              showNotify({
                type: "success",
                message: "人脸照片更新成功！",
                duration: 2000,
              });
              queryGetSignStuDetailData();
            } else {
              showNotify({
                type: "warning",
                message: "人脸照片更新失败！",
                duration: 2000,
              });
            }
          });
        }
        
      } catch (e) {
        console.log(e);
      }
    };

    const queryGetSignStuDetailData = async () => {
      try {
        const result = await queryGetSignStuDetail({
          id: route?.query?.userId,
        });
        console.log("result>>>>", result);
        state.studentInfo = result.data ?? {};
      } catch (error) {
        console.log("error>>>>", error);
      }
    };

    const handleGoToSignin = () => {
      router.push({
        path: "/signIn",
        query: {
          userId: state.userId,
          businessUserId: state.studentInfo?.businessUserId,
          userName: state.studentInfo?.realName,
          checkInDate: state.studentInfo?.checkInDate,
          expireDate: state.studentInfo?.expireDate,
          performanceId: state.studentInfo?.performanceId,
        },
      });
    };

    const handleGoTravelRecords = () => {
      router.push({
        path: "/travelRecords",
        query: {
          userId: state.userId,
        },
      });
    };

    const handleGoEditStudentInfo = () => {
      router.push({
        path: "/editStudentsInfo",
        query: {
          userId: state.userId,
        },
      });
    };

    const handleGoMealRecords = () => {
      router.push({
        path: "/mealRecord",
        query: {
          userId: state.userId,
        },
      });
    };

    const handleGoiSignInRecords = () => {
      router.push({
        path: "/signInRecord",
        query: {
          userId: state.userId,
        },
      });
    };

    const getDay = (day) => {
      return dayjs(day).format("YYYY-MM-DD");
    };

    return {
      handleGoToSignin,
      handleGoTravelRecords,
      handleGoMealRecords,
      handleGoiSignInRecords,
      state,
      DefaultAvatar,
      getImg,
      HospitalStatusText,
      SexText,
      getDay,
      afterRead,
      avatarImg,
      handleGoEditStudentInfo
    };
  },
});
</script>

<style lang="scss" scoped>
.page-container {
  background: #e9f1f8;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;

  .content-item {
    background: #fff;
    margin: 12px 8px;
    border-radius: 4px;
    padding: 12px;
    text-align: left;
  }

  .item-header {
    display: flex;
  }

  .item-header-info {
    display: flex;
    justify-content: space-between;
    padding: 4px 0;
    align-items: center;

    .item-des {
      color: #333333;
      font-size: 14px;
      vertical-align: middle;
    }

    .item-header-info-name {
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 18px;
      color: #000000;
      font-weight: 600;

      span {
        font-size: 16px;
        font-weight: 500;
        color: burlywood;
      }
    }

    .item-header-info-status {
      color: #fff;
      font-size: 14px;
      background: #113050;
      padding: 4px 12px;
      border-radius: 24px;
    }

    &-text {
      color: #113050;
      font-size: 12px;
      padding: 4px 12px;
    }

    &-value {
      font-size: 12px;
      padding: 4px 12px;
    }

    .item-header-info-other {
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      display: flex;
      align-items: center;

      p {
        color: #ff3638;
      }
    }
  }

  .header-title {
    color: #000000;
    font-weight: 500;
    margin-top: 4px;

    span {
      font-weight: 400;
      color: #2c3e50;
    }
  }
}
</style>