<script lang="jsx">
  import { defineComponent, ref, watch } from 'vue';
  import _ from 'lodash-es';
  import SortIcon from '@/components/SortIcon.vue';
  import EmptyDataCard from '@/components/EmptyDataCard.vue';
  import { SORT_ORDER } from '@/config/tableConfig.js';

  export default defineComponent({
    emits: ['tdClick'],
    props: {
      /**
       * @param title 标题
       * @param unit 单位
       * @param dataIndex 索引
       * @param width 宽度
       * @param sortable 是否开启排序
       * @param onSort 排序点击的回调
       */
      columns: {
        type: Array,
        require: true,
      },
      data: {
        type: Array,
        default: () => [],
      },
    },
    setup(props, { emit }) {
      const columnsState = ref(props.columns);
      const dataState = ref(props.data);

      function handleSort(col, index) {
        switch (col.sortOrder) {
          case 'asc':
            col.sortOrder = SORT_ORDER.DESC;
            break;
          case 'desc':
            col.sortOrder = SORT_ORDER.ALL;
            break;
          case 'all':
            col.sortOrder = SORT_ORDER.ASC;
            break;
          default:
            col.sortOrder = SORT_ORDER.ALL;
            break;
        }

        columnsState.value.forEach((item) => {
          if (item.sortable && col.dataIndex !== item.dataIndex) {
            item.sortOrder = SORT_ORDER.ALL;
          }
        });
        emit('sort', col.sortOrder, index, col);
      }

      watch(
        () => props.columns,
        (newValue, oldValue) => {
          if (!_.isEqual(newValue, oldValue)) {
            columnsState.value = newValue;
          }
        },
        {
          deep: true,
        },
      );

      watch(
        () => props.data,
        (newValue, oldValue) => {
          if (!_.isEqual(newValue, oldValue)) {
            dataState.value = newValue;
          }
        },
        {
          deep: true,
        },
      );

      if (dataState.value.length <= 0) {
        return () => <EmptyDataCard />;
      }

      return () => (
        <table className="sort-table">
          <thead className="thead">
            {columnsState.value.map((col, index) => {
              const startBool = col?.start;
              const endBool = col?.end;

              if (col.sortable) {
                const { sortOrder } = col;

                return (
                  <th
                    {...col}
                    className={'th th-sortable' + sortOrder}
                    onClick={() => handleSort(col, index)}>
                    <div className="header-title">
                      <span className="th-title">{col.title}</span>
                      {col.unit && <span className="title">{col.unit}</span>}
                    </div>
                    <SortIcon sortOrder={sortOrder} />
                  </th>
                );
              }
              return (
                <th
                  {...col}
                  style={{textAlign: startBool ? 'left' : endBool ? 'right' : 'center'}}
                  className="th">
                  <div className="header-title">
                    <span className="th-title">{col.title}</span>
                    {col.unit && <span className="title">{col.unit}</span>}
                  </div>
                </th>
              );
            })}
          </thead>
          <tbody className="tbody">
            {dataState.value.map((rowData) => {
              return (
                <tr className="tr">
                  {columnsState.value.map((column) => {
                    const fieldId = column.dataIndex;
                    const desNum = column?.des;
                    const tagBool = column?.tag;
                    const startBool = column?.start;
                    const endBool = column?.end;
                    const cellValue = rowData[fieldId] ?? '--';
                    const desValue = rowData[desNum];
                    const tagValue = rowData['tagValue'];

                    console.log(tagValue,tagBool)


                    const params = {
                      column,
                      value: cellValue,
                      columnId: fieldId,
                      rowData: rowData,
                    };

                    function handleTdClick() {
                      emit('tdClick', params);
                    }

                    if (column?.render) {
                      return (
                        <td
                          className="td"
                          onClick={handleTdClick}>
                          {column.render(params)}
                        </td>
                      );
                    }
                    return (
                      <td
                        style={{textAlign: startBool ? 'left' : endBool ? 'right' : 'center'}}
                        className="td"
                        onClick={handleTdClick}>
                        <div className="header-title">
                          {tagBool && <span className="th-tag" style={{backgroundColor: tagValue}}></span>}
                          <span className="th-title">{cellValue}</span>
                          {desValue && <span className="title">{desValue}</span>}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    },
  });
</script>

<style scoped lang="scss">
  $borderColor: #e5e6eb;

  .sort-table {
    width: 100%;
    font-size: 12px;
    color: #1d2129;
    // border: 1px solid $borderColor;
    border-collapse: collapse;
    table-layout: fixed;
    .thead {
      color: #86909c;
      background: #fff;
      
      .header-title {
        display: inline-block;
        vertical-align: middle;
        text-align: center;
      }
      .th-title {
        display: block;
        font-weight: 600;
      }
      .th {
        font-weight: 400;
        height: 32px;
        text-align: center;
        padding: 6px 0 6px 3%;
        box-sizing: border-box;
        white-space: nowrap;
      }
    }
    .tbody {
      .tr {
        // border-bottom: 1px solid $borderColor;
      }
      .td {
        min-height: 40px;
        padding: 10px 0 10px 3%;
        box-sizing: border-box;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .header-title {
        display: inline-block;
        vertical-align: middle;
      }
      .th-title {
        display: inline-block;
      }
      .title {
        display: inline-block;
      }

      .th-tag {
        display: inline-block;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        margin-right: 4px;
      }
    }
    .th-sortable {
      .title {
        display: inline-block;
      }
    }
  }
</style>
