export const ModuleType = {
    College: 1, // 书院学生
    InCourtyard: 2,  // 在院
    Due: 3  // 即将到期
}

export const ModeType = {
    ReturnHospital: 1, // 回院
    Lunch: 2,  // 午餐
    NoonBreak: 3,  // 午休
    LeavingHospital: 4  // 离院
}

export const HospitalStatusText = {
    1: '在院',
    2: '离院'
}

export const SexText = {
    1: '男',
    2: '女'
}


export const SigningStatusText = { 
    1: "签约中", 
    2: "已过期", 
    3: "已终止" 
};