<script setup>
  import EmptyData from '@/components/EmptyData.vue';
  import SortTable from '@/components/SortTable.vue';
  import { reactive } from 'vue';

  defineProps({
    loading: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      required: true,
      default: () => {
        return {
          pageNo: 1,
          pageSize: 10,
          totalCount: 0,
          result: [],
        };
      },
    },
  });

  const emit = defineEmits(['changePage']);
  const pager = reactive({
    pageNo: 1,
    pageSize: 10,
    totalCount: 0,
  });
  function handlePageChange(pageNo) {
    emit('changePage', pageNo);
  }
</script>

<template>
  <div class="sort-pagination-table">
    <div>
      <div
        class="sort-pagination-table-main"
        v-if="data.length > 0">
        <SortTable
          v-bind="$attrs"
          :data="data" />
      </div>
      <div
        v-else
        class="empty-wrap">
        <EmptyData />
      </div>
      <!-- <div
        v-if="data.length > 0"
        class="pagination-wrap">
        <VanPagination
          force-ellipses
          v-model="pager.pageNo"
          :total-items="data.totalCount"
          :items-per-page="data.pageSize"
          @change="handlePageChange">
          <template #prev-text>
            <VanIcon name="arrow-left" />
          </template>
          <template #next-text>
            <VanIcon name="arrow" />
          </template>
          <template #page="{ text }">{{ text }}</template>
        </VanPagination>
      </div> -->
    </div>
    <div
      class="table-loading-wrap"
      v-show="loading">
      <VanLoading
        class="table-loading"
        color="#22b894"
        vertical>
        数据加载中
      </VanLoading>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .sort-pagination-table-main {
    overflow-x: auto;
  }
  .sort-pagination-table {
    position: relative;
    .table-loading-wrap {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.8);
    }
    .pagination-wrap {
      margin-top: 9px;
      display: flex;
      justify-content: center;
      padding-bottom: 12px;
    }

    :deep(.van-pagination__item) {
      width: 32px !important;
      height: 32px !important;
      flex: none;
      color: #4e5969;
    }

    :deep(.van-pagination__item button) {
      width: 32px;
    }

    :deep(.van-pagination__item--active) {
      background: #f0e8ff;
      color: #532fe6;
    }

    // :deep(.sort-table) {
    //   border: 1px solid #e5e6eb;
    // }

    :deep(.sort-table .thead) {
      color: #1d2129;
      background-color: #fff;
    }

    :deep(.sort-table .th) {
      border-bottom: 1px solid #e5e6eb;
    }

    :deep(.sort-table .td) {
      border-bottom: 1px solid #e5e6eb;
    }
  }
</style>
