<template>
  <div class="page-exchange">
    <div class="page-exchange-card">
      <div @click="handleSeeDetail" class="card-title">我的积分 ></div>
      <div class="card-value">105</div>
    </div>

    <div class="page-exchange-list">
      <div class="list-title">兑换礼品</div>
      <div class="list-item-card">
        <div class="list-item" v-for="(item, index) in state.list" :key="index">
        <div class="item-card">
          <van-image
            width="156px"
            height="81px"
            :src="item.images"
          ></van-image>
        <div class="item-card-info">
           <div class="list-item-name">{{item.name}}</div>
           <div class="list-item-remaining" v-if="item.remaining">剩余{{item.remaining}}</div>
        </div>
        <div class="list-item-points">
          <van-image
            width="14px"
            height="14px"
            class="point-icon"
            :src="PointsIcon"
          ></van-image>
          {{item.points}}</div>
        </div>

        <div class="list-item-btn">
          <van-button :class="['van-btn-custom', item.remaining === 0 ? 'van-btn-disabled' : '']" size="small" @click="exchange(item.id)"> {{ item.remaining === 0 ? '积分不足' : '立即兑换'}}</van-button>
        </div>
      </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import PointsIcon from '@/assets/poiints_icon.png'
import { useRouter, useRoute } from "vue-router";


export default defineComponent({
  name: "PointsExchange",

  components: {},

  setup() {

    const router = useRouter();

    const route = useRoute();

    const state = reactive({
      list: [
        {
          name: '10 U米',
          points: '100积分',
          id: 1,
          images: 'https://img.yzcdn.cn/vant/apple-1.jpg'
        },
        {
          name: '50 U米',
          points: '500积分',
          id: 2,
          images: 'https://img.yzcdn.cn/vant/apple-1.jpg'
        },
        {
          name: 'U宝书签',
          points: '100积分',
          remaining: 0,
          id: 3,
          images: 'https://img.yzcdn.cn/vant/apple-1.jpg'
        },
        {
          name: 'U宝直尺',
          points: '50积分',
          remaining: 90,
          id: 4,
          images: 'https://img.yzcdn.cn/vant/apple-1.jpg'
        }
      ]
    });


    onMounted(() => {
    });

    const handleSeeDetail = () => {
      router.push('/points/detail');
    }

    return {
      state,
      PointsIcon,
      handleSeeDetail
    };
  },
});
</script>

<style lang="scss" scoped>
.page-exchange {
  background: #e9f1f8;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
  padding: 0;
  margin: 0;



  .page-exchange-card {
    width: calc(100% - 20px);
    height: 124px;
    background: #FFFFFF;
    border-radius: 7px;
    margin: 10px 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    .card-title {
      font-family: PingFang SC;
      font-weight: 600;
      font-size: 17px;
      color: #000000;
    }

    .card-value {
      margin-top: 8px;
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 30px;
      color: #000000;
    }
  }

  .page-exchange-list {
    margin: 10px 10px 0;
    width: calc(100% - 20px);
    
    .list-title {
      height: 52px;
      line-height: 52px;
      font-family: PingFang SC;
      font-weight: 900;
      font-size: 17px;
      color: #000000;
      text-align: left;
    }

    .list-item-card{
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between;

      .list-item {
        width: calc(50% - 5px);

        .item-card {
           background: #FFFFFF;
           border-radius: 7px;
           padding-bottom: 12px;
        }

        .item-card-info {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-top: 22px;

          .list-item-name {
            margin-left: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 17px;
            color: #000000;
          }
          .list-item-remaining {
            margin-right: 10px;
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 15px;
            color: #8E8E8E;
          }
        }


        .list-item-points {
          margin-top: 4px;
          width: 100%;
          text-align: left;
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 15px;
          color: #FFB839;
          display: flex;
          align-items: center;

          .point-icon {
            margin-left: 15px;
            margin-right: 4px;
          }
        }
       
      }

      .list-item-btn {
        margin: 18px 0;

        .van-btn-custom {
          background: #265883;
          width: 81px;
          height: 33px;
          color: white;
        }

        .van-btn-disabled {
          background: #A5A5A5;
          color: #FEFFFF;
        }
      }
    }
  }
}
</style>