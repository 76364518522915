import axios from 'axios'
// import { Notify } from 'vant'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    console.log('config>>>>',config)
    const token = localStorage.getItem('token')
    if (token) {
      config.headers['token'] = token
    }

    if(config.url === '/syUser/loginEmp' || config.url === '/syUser/logInSendCode') {
      delete config.headers['token']
    }
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

service.interceptors.response.use(

  response => {
    const res = response.data
    if (res.code !== '00') {
      // Notify({
      //   message: res.message || 'Error',
      //   type: 'warning',
      //   duration: 5 * 1000
      // })
      return res
    } else {
      if (response.config.url.endsWith('/syUser/loginEmp')) {
        localStorage.setItem('token', response.headers.token)
      }
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    // Notify({
    //   message: error.message,
    //   type: 'danger',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  }
)

export default service
